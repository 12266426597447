(function() {
    angular.module('EntrakV5').controller('gatewayController', gatewayController);

    function gatewayController($scope, $rootScope, $timeout, Service, Api, KEY, $state, $stateParams) {
        console.log('gatewayController', $state.current.name);

        //$stateParams.tenantId
        //$stateParams.floorId
        $scope.isAllGateway = $state.current.name === 'allGateway';
        var caller = Api.createApiCaller();
        var nameSorter = Service.getSorter();
        $scope.btnStatus = {};

        $rootScope.hideTabs = !$scope.isAllGateway;

        $scope.gateways = null;
        $scope.hubMap = null;
        $scope.floorMap = {};
        $scope.buildingMap = null;//must be null when init
        $scope.chartData = null;//must be null when init
        $scope.filter = {
            searchText: '',
            buildingId: 'all',
            status: 'all',
            enable: 'enable',
        }

        //init breadcrumbs
        if (!$scope.isAllGateway){
            $scope.steps = [ 
                $rootScope.STEP1, {
                    name: '-',
                    navigate: function(){
                        $state.go("tenant", { tenantId: $stateParams.tenantId });
                    }
                }, {
                    name: '-',
                    navigate: function(){
                        $state.go("floor", { floorId: $stateParams.floorId, tenantId: $stateParams.tenantId });
                    }
                }, {
                    name: Service.translate("tenant.gatewayTab")
                }
            ];
        }

    /* first load */
        $rootScope.loadingPage = 0;
        $scope.loadingGatewaysData = function(){
          $scope.btnStatus.refreshGw = true;
          if ($scope.isAllGateway){
              $rootScope.loadingPage++;
              caller.call(Api.getGateways()).then(function(res){//all fields used
                  $scope.getFloors(res);
                  processGateways(res);
                  $rootScope.loadingPage--;
                  $scope.btnStatus.refreshGw = false;
              }, function(err){
                  if (err === KEY.ignore)
                      return;
                  $rootScope.loadingPage--;
                  $scope.btnStatus.refreshGw = false;
                  alert(Service.translate("error.generalGetDataFail"));
              });
          } else {
              $rootScope.getLandlordId().then(function(id){
                  //update breadcrumbs display and get floor
                  $rootScope.loadingPage++;
                  $rootScope.updateTenantStep2Name($scope.steps, $stateParams.tenantId, $stateParams.floorId, true, caller, false, true).then(function(res){
                      $scope.getFloors(res.gateways);
                      processGateways(res.gateways);
                      $rootScope.loadingPage--;
                      $scope.btnStatus.refreshGw = false;
                  }, function(err){
                      if (err === KEY.ignore)
                          return;
                      console.error('invalid floor/tenant id');
                      $rootScope.loadingPage--;
                      $scope.btnStatus.refreshGw = false;
                      alert(Service.translate("error.generalGetDataFail"));
                  });
              });
          }
        }
        $scope.loadingGatewaysData();

        $rootScope.loadingPage++;
        caller.call([Api.getHubs(), Api.getBuildings()]).then(function(res){
            var buildings = [];
            var tmp;
            $scope.hubMap = Service.arrayToMap(res[0]);
            res[1].sort(nameSorter);
            res[1].forEach(function(landlord){
                landlord.buildings.sort(nameSorter);
                for (var i=0; i<landlord.buildings.length; i++){
                    tmp = landlord.buildings[i];
                    buildings.push({
                        name: tmp.name,
                        longName: tmp.name + "(" + landlord.name + ")",
                        id: tmp.id
                    });
                }
            });
            $scope.buildingMap = Service.arrayToMap(buildings);
            $scope.buildingSelect.setDataSource(new kendo.data.DataSource({
                data: buildings
            }));
            $scope.buildingDropdown.setDataSource(new kendo.data.DataSource({
                data: [{id: 'all', name: Service.translate("tenant.allBuilding")}].concat(buildings)
            }));
            $scope.filter.buildingId = 'all';

            loadChartData();
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });

        $scope.getFloors = function(gateways){
          $rootScope.loadingPage++;
          var zMap = {};
          gateways.forEach(function(g) {
            g.zoneIds.forEach(function(zId) {
              zMap[zId] = zId;
            });
          });
          caller.call(Api.getFloors(Object.keys(zMap))).then(function(res){//all fields used
            $scope.floorMap = Service.arrayToMap(res);
            gateways.forEach(function(g){
              g.bMap = {};    // { buildingId: [{name: floorName, id: floorId}] }
              g.zoneIds.forEach(function(zId){
                var bId = $scope.floorMap[zId].buildingId;
                if (!g.bMap[bId])
                  g.bMap[bId] = [];
                g.bMap[bId].push({name: $scope.floorMap[zId].name, id: zId});
              });
              for (var b in g.bMap){
                if (g.bMap.hasOwnProperty(b)){
                  g.bMap[b].sort(nameSorter);
                }
              }
            });
            prepareOfflineData(gateways);
            loadChartData();
            $rootScope.loadingPage--;
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
          });
        }

        function processGateways(gateways){
            $scope.gateways = gateways.sort(Service.getSorter('serialId'));
            $scope.gateways.forEach(processGateway);
        }
        function processGateway(res){
            res.deviceCount = 0;
            for (var d in res.count){
                if (res.count.hasOwnProperty(d) && d !== "offline" && d !== "__typename"){
                    res.deviceCount += res.count[d];
                }
            }
            res.onlineDeviceCount = res.deviceCount - res.count.offline;
        }
        function prepareOfflineData(gateways){
            var byBuilding = {};
            var byFloor = {};
            var total = 0;
            var totalOnline = 0;
            gateways.forEach(function(gateway){
              if (gateway.enabled){//charts only include enabled gw
                if (!gateway.online){
                    var processedBuilding = {};
                    gateway.zoneIds.forEach(function(zId){
                        if (byFloor[zId]){
                            byFloor[zId]++;
                        } else {
                            byFloor[zId] = 1;
                        }
                        var bId = $scope.floorMap[zId].buildingId;
                        if (!processedBuilding[bId]){
                            processedBuilding[bId] = true;
                            if (byBuilding[bId]){
                                byBuilding[bId]++;
                            } else {
                                byBuilding[bId] = 1;
                            }
                        }
                    });
                }
                if (gateway.zoneIds.length){
                    total++;
                    if (gateway.online)
                        totalOnline++;
                }
              }
            });
            $scope.chartData = {
                onelinePercent: total ? (100 * totalOnline / total) : 0,
                byBuilding: byBuilding,
                byFloor: byFloor
            }
        }
        function loadChartData(){
            if ($scope.chartData && $scope.buildingMap){//wait until both data received
                $scope.pieChartByBuilding.setDataSource(new kendo.data.DataSource({
                    data: Object.keys($scope.chartData.byBuilding).map(function(id){
                        return {
                            name: $scope.buildingMap[id].name,
                            count: $scope.chartData.byBuilding[id],
                            buildingId: id
                        }
                    })
                }));
                $scope.pieChartByFloor.setDataSource(new kendo.data.DataSource({
                    data: Object.keys($scope.chartData.byFloor).map(function(id){
                        return {
                            name: $scope.floorMap[id].name,
                            count: $scope.chartData.byFloor[id]
                        }
                    })
                }));
                $scope.gauge.value($scope.chartData.onelinePercent);
            }
        }

        $scope.gotoLocation = function(gatewayId, zoneId){
            if ($scope.isAllGateway) {
                caller.call(Api.getTenantByZone(zoneId)).then(function(res){
                    if (res) {
                        $state.go("floor", { floorId: zoneId, tenantId: res.id, gatewayId: gatewayId });
                    } else {
                        alert(Service.translate("error.tenantNotFound"));
                    }
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalGetDataFail"));
                });
            } else {
                $state.go("floor", { floorId: zoneId, tenantId: $stateParams.tenantId, gatewayId: gatewayId });
            }
        }
    /* first load */

    /* pie charts */
        $scope.gwFilter = function(){
            var txt = $scope.filter.searchText.trim().toUpperCase();
            var bId = $scope.filter.buildingId;
            var status = $scope.filter.status;
            var enable = $scope.filter.enable;
            if (txt || bId !== 'all' || status !== 'all' || enable !== 'all'){
                return function(item){
                    return (!txt || item.serialId.toUpperCase().indexOf(txt) != -1 || item.id.toUpperCase().indexOf(txt) != -1)
                        && (bId === 'all' || bId !== 'all' && item.bMap[bId])
                        && (enable === 'all' || enable === 'enable' && item.enabled || enable === 'disable' && !item.enabled)
                        && (status === 'all' || status === 'on' === item.online);
                }
            }
        }

        $scope.buildingDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id"
        }

        $scope.statusDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                name: Service.translate("tenant.allStatus"),
                value: "all"
            }, {
                name: Service.translate("tenant.online"),
                value: 'on'
            }, {
                name: Service.translate("tenant.offline"),
                value: 'off'
            }],
            dataTextField: "name",
            dataValueField: "value"
        }

        $scope.enableDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                name: Service.translate("label.enabledDisabled"),
                value: "all"
            }, {
                name: Service.translate("label.enabled"),
                value: 'enable'
            }, {
                name: Service.translate("label.disabled"),
                value: 'disable'
            }],
            dataTextField: "name",
            dataValueField: "value"
        }

        $scope.pieChartOpt = {
            legend: {
                visible: false
            },
            seriesDefaults: {
                type: "pie",
                padding: 10,
                overlay: {
                    gradient: "none"
                },
                highlight: {
                    visible: false
                },
            },
            chartArea: {
                background: "",
                margin: {
                    bottom: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                },
            },
            plotArea: {
                margin: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }
            },
            series: [{
                field: "count",
                categoryField: "name"
            }],
            tooltip: {
                visible: true,
                template: "#=category# (#=value#)"
            },
            seriesClick: function(e) {
              if (e.dataItem.buildingId) {
                $timeout(function(){
                  $scope.filter.searchText = "";
                  $scope.filter.status = "off";
                  $scope.filter.buildingId = e.dataItem.buildingId;
                });
              }
            }
        }

        $scope.gaugeOpt = {
            gaugeArea: {
                height: 150,
            },
            scale: {
                min: 0,
                max: 100,
                majorTicks: {
                    color: KEY.chartBorderColor,
                },
                minorTicks: {
                    color: KEY.chartBorderColor,
                },
                minorUnit: 5,
                labels: {
                    color: KEY.chartFontColor,
                    format: "{0}%",
                },
                rangeSize: 15,
                ranges: [{
                    from: 0,
                    to: 30,
                    color: KEY.chartRedColor
                }, {
                    from: 30,
                    to: 70,
                    color: KEY.chartOrangeColor
                }, {
                    from: 70,
                    to: 100,
                    color: KEY.chartGreenColor
                }]
            }
        }

        var overview = $("#overview");
        $scope.toggleOverview = function(show){
            if (show == null){
                if (overview.is(":visible")){
                    overview.slideUp('fast');
                } else {
                    overview.slideDown('fast');
                }
            } else if (show){
                overview.slideDown('fast');
            } else {
                overview.slideUp('fast');
            }
        }
    /* pie charts */

    /* offline log */
        $scope.selectedGwId = null;
        $scope.offlineData = null;
        $scope.showGatewayLog = function(gId){
            if ($scope.selectedGwId === gId){
                $scope.selectedGwId = null;
                $scope.toggleOverview(true);
            } else {
                $scope.loadGatewayLog(gId);
            }
        }
        $scope.loadGatewayLog = function(gId) {
          if (!gId)
            return;
          $rootScope.loadingPage++;
          $scope.selectedGwId = gId;
          $scope.toggleOverview(false);
          var logDurationInDay = 7;
          var dayLength = 24 * 3600;
          var startTime = new Date();
          startTime.setHours(0, 0, 0, 0);
          startTime = Service.getUnixTimestamp(startTime) - logDurationInDay * dayLength;//get 1 more day before start time
          var displayStartTime = startTime + dayLength;
          caller.call(Api.getGatewayOnlineLog(gId, startTime)).then(function(res){//assume sorted
              if ($scope.selectedGwId === gId){
                res = Service.processStatusLog(res, null, displayStartTime, logDurationInDay);
                $scope.offlineData = res.barData;
                $scope.offlineDataRecords = res.displayOnlineLog;
              }
              $rootScope.loadingPage--;
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $scope.offlineData = null;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
          });
        }

        $scope.barTooltipOpt = {
            filter: ".bar",
            position: "top",
            width: "auto",
            callout: true,
            animation: {
                open: {
                    effects: "zoom",
                    duration: 150
                }
            },
            content: function(e) {
                var t = e.target;
                var txt = Service.timeFmt(parseInt(t.data("start"))) + ' - ' + Service.timeFmt(parseInt(t.data("end")));
                if (t.hasClass("online")){
                    txt += " (" + Service.translate("tenant.online") + ")";
                } else if (e.target.hasClass("offline")){
                    txt += " (" + Service.translate("tenant.offline") + ")";
                }
                return txt;
            },
            show: function(e) {
                e.sender.popup.element.css({"margin-top": "-5px"});
            }
        }
    /* offline log */

    /* step3 (isGateway) */
        $scope.editGatewayData = {};
        $scope.editGatewayWinOpt = {
            title: Service.translate("tenant.popup.editGateway"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.confirmEditGateway = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.updateGateway($scope.editGatewayData.id, $scope.editGatewayData.enabled, $scope.editGatewayData.remark)).then(function(res){
                processGateway(res);
                Service.replaceArrItem($scope.gateways, res);
                $scope.btnStatus.saving = false;
                $scope.editGatewayWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdateGateway"));
            });
        }
        $scope.editGateway = function(gateway, $event){
            $event.stopPropagation();
            $scope.editGatewayData.id = gateway.id;
            $scope.editGatewayData.enabled = gateway.enabled;
            $scope.editGatewayData.remark = gateway.remark ? gateway.remark : '';
            setTimeout(function(){
                $scope.editGatewayWin.center().open();
            });
        }

        $scope.gatewayMenuId = null;
        $scope.gatewayMenuOpt = {
            anchor: $("#tenant #deviceInfoMap .tooltipAnchor div"),
            origin: "top left",
            position: "top right",
        }
        $scope.openGatewayMenu = function(gateway, $event){
            $event.stopPropagation();
            $scope.gatewayMenuId = gateway.id;
            $scope.gatewayMenuSerial = gateway.serialId;
            $scope.gatewayMenuProvider = gateway.provider;
            $scope.gatewayMenu.setOptions({anchor: $("#tenant .gatewayTable #" + gateway.id + " .editBtn")});
            $scope.gatewayMenu.open();
        }
        $scope.deleteGateway = function(){
            $scope.gatewayMenu.close();
			
            var gatewayId = $scope.gatewayMenuId;
			let serial = $scope.gatewayMenuSerial /*change the delete confirmation to serial :*/
            $rootScope.deletePopup.show("tenant.popup.deleteGateway", "tenant.popup.deleteGatewayDesc", serial, function(){
                caller.call(Api.deleteGateway(gatewayId)).then(function(res){
                    Service.deleteArrItem($scope.gateways, gatewayId);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }
        $scope.scanDevice = function(registerOnly){
            $scope.gatewayMenu.close();
            $scope.btnStatus.saving = true;
            caller.call(Api.scanDevices($scope.gatewayMenuId, registerOnly)).then(function(res){
                $scope.btnStatus.saving = false;
                if (res.success){
                    $rootScope.infoPopup.show(registerOnly ? "button.regDevice" : "button.scanDevice", "label.scanDesc");
                } else {
                    alert(Service.translate("error.generalScanFail"));
                }
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalScanFail"));
            });
        }
        $scope.registerAllDevices = function(){
            $scope.btnStatus.saving = true;
            var map = {};
            var calls = [];
            var f = $scope.gwFilter();
            (f ? $scope.gateways.filter(f) : $scope.gateways).forEach(function(g){
                map[g.hubId] = g.hubId;
                calls.push(Api.scanDevices(g.id, true));
            });
            Object.keys(map).forEach(function(hubId){
                calls.push(Api.scanGateways(hubId));
            });
            caller.call(calls).then(function(res){
                $scope.btnStatus.saving = false;
                for (var i=0; i<res.length; i++){
                    if (!res[i].success){
                        alert(Service.translate("error.generalScanFail"));
                        return;
                    }
                }
                $rootScope.infoPopup.show("button.regDevice", "label.scanDesc");
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalScanFail"));
            });
        }

        $scope.backupGateway = function(){
            $scope.gatewayMenu.close();
            var gId = $scope.gatewayMenuId;
            $rootScope.confirmPopup.show("tenant.popup.backupGateway", "tenant.popup.backupGatewayDesc", gId, function(){
                caller.call(Api.backupGateway(gId)).then(function(res){
                    $rootScope.infoPopup.show("button.backup", "label.backupGateway");
                    $scope.gateways.find(function(g){
                      return g.id === gId;
                    }).lastBackupDate = res.lastBackupDate;
                    $rootScope.confirmPopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalBackupFail"));
                });
            });
        }

        $scope.restoreGatewayData = {};
        $scope.restoreGatewayWinOpt = {
            title: Service.translate("tenant.popup.restoreGateway"),
            width: "640px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.restoreDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "lbl",
            dataValueField: "versionId"
        }
        var timer = null;
        $scope.$watch("restoreGatewayData.fromSerial", function(n, o) {
          if (!n)
            return;
          $timeout.cancel(timer);
          timer = $timeout(function() {
            $scope.initRestoreList(n);
          }, 400);
        });
        $scope.toggleFromOther = function(){
          if ($scope.restoreGatewayData.fromOtherGateway) {
            $scope.initRestoreList($scope.restoreGatewayData.fromSerial);
          } else {
            $scope.initRestoreList($scope.restoreGatewayData.defaultFromSerial);
          }
        }
        $scope.initRestoreList = function(serial) {
            if (!$scope.restoreGatewayData.backups)
              return;
            var tmp = $scope.restoreGatewayData.backups[serial];
            if (tmp && !tmp.processed && tmp.versions){
              tmp.versions.forEach(function(v){
                v.lbl = Service.datetimeFmt(v.lastModified);
              });
              tmp.processed = true;
            }
            if (tmp){
              $scope.restoreDropdown.setDataSource(new kendo.data.DataSource({
                  data: tmp.versions
              }));
              $scope.restoreGatewayData.version = tmp.versions[0].versionId;
            } else {
              $scope.restoreDropdown.setDataSource(new kendo.data.DataSource({
                  data: []
              }));
              $scope.restoreGatewayData.version = null;
            }
        }
        $scope.restoreGateway = function(){
          $scope.gatewayMenu.close();
          $scope.btnStatus.saving = true;
          var serial = $scope.gatewayMenuSerial;
          caller.call(Api.getGatewayBackups($scope.gatewayMenuProvider)).then(function(res){
              $scope.btnStatus.saving = false;
              setTimeout(function(){
                  $scope.restoreGatewayWin.center().open();
              });
              $scope.restoreGatewayData.fromSerial = null;
              $scope.restoreGatewayData.fromOtherGateway = false;
              $scope.restoreGatewayData.backups = Service.arrayToMap(res, "serial");
              $scope.initRestoreList(serial);
              $scope.restoreGatewayData.defaultFromSerial = serial;
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $scope.btnStatus.saving = false;
              alert(Service.translate("error.generalGetDataFail"));
          });
        }
        $scope.confirmRestoreGateway = function(){
            $scope.btnStatus.saving = true;
            var gId = $scope.restoreGatewayData.backups[$scope.restoreGatewayData.fromOtherGateway ? $scope.restoreGatewayData.fromSerial : $scope.restoreGatewayData.defaultFromSerial].gatewayId;
            caller.call(Api.restoreGateway($scope.gatewayMenuId, gId, $scope.restoreGatewayData.version)).then(function(res){
                $scope.btnStatus.saving = false;
                $scope.restoreGatewayWin.close();
                $rootScope.infoPopup.show("button.restore", "label.restoreGateway");
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalRestoreFail"));
            });
        }

        $scope.editHubData = {};
        $scope.editHubWinOpt = {
            title: Service.translate("hub.popup.editHub"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.buildingSelectOpt = {
            autoWidth: true,
            // autoClose: false, //kendo bug? if false, the text wont clear when you select an item
            clearButton: false,
            filter: "contains",
            dataSource: [],
            dataTextField: "longName",
            dataValueField: "id"
        }

        $scope.editHub = function(hubId, $event){
            $event.stopPropagation();
            var hub = $scope.hubMap[hubId];
            $scope.editHubData = {
                id: hub.id,
                serial: hub.serialId,
                enabled: hub.enabled,
                remark: hub.remark,
                buildingIds: hub.buildings ? hub.buildings.map(function(b){
                    return b.id
                }) : []
            }
            setTimeout(function(){
                $scope.editHubWin.center().open();
            });
        }
        $scope.confirmEditHub = function(){
            $scope.btnStatus.saving = true;
            var remark = $scope.editHubData.remark.trim();
            var id = $scope.editHubData.id;
            caller.call(Api.updateHub(id, $scope.editHubData.buildingIds, $scope.editHubData.enabled, remark)).then(function(res){
                $scope.editHubWin.close();
                $scope.hubMap[id] = res;
                $scope.btnStatus.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdateHub"));
            });
        }

        $scope.scanGateway = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.scanGateways($scope.editHubData.id)).then(function(res){
                $scope.btnStatus.saving = false;
                if (res.success){
                    $scope.editHubWin.close();
                    $rootScope.infoPopup.show("button.scanGateway", "label.scanDesc");
                } else {
                    alert(Service.translate("error.generalScanFail"));
                }
            }, function(err){
                if (err === KEY.ignore)
                        return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalScanFail"));
            });
        }
    /* step3 (isGateway) */

        $scope.$on('$destroy', function() {
            console.log("gatewayController destroy");
            caller.cancel();
        });
    }
})();